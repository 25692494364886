import React from "react";
import { Link } from "gatsby";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import FaqAccordion from "../../components/faq/faq-accordion";
import ContactInfo from "../../components/contact-info/contact-info";

// Helpers
import getSortedFaqDataByCategory from "../../helpers/getSortedFaqDataByCategory";

const FAQ = () => {
  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Customer Service"]);

  const title = "Frequently Asked Questions (FAQs)";
  const description =
    "Have a question about WaFd bank such as what is WaFd Bank's routing number? We answer your top questions here!";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/customer-service/faq"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-faq-01-250.jpg"
      }
    ],
    schema
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "FAQs"
    }
  ];

  const faqAccordionData = {
    id: "customer-service-faqs",
    pageTitle: "WaFd Bank Frequently Asked Questions",
    title: null,
    // pass faqData instead of faqCategoryNames
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <div id="alert-stripe-messages" className="bg-success text-white py-2 text-center">
        Have a question about online or mobile banking at WaFd Bank?{" "}
        <Link
          id="alert-stripe-online-banking-faq-link"
          className="text-white hybrid-link"
          to="/personal-banking/online-banking/faq"
        >
          Visit our Mobile Banking FAQs!
        </Link>
      </div>
      <Breadcrumb data={breadcrumbData} />
      <FaqAccordion {...faqAccordionData} />
      <ContactInfo />
    </SecondaryLanding>
  );
};

export default FAQ;
